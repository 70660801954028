import { createPublicClient, http } from "viem";
import { taikoHekla, holesky, mainnet, taiko } from "viem/chains";

const chain = {
  testnet: {
    CHAIN: taikoHekla,
  },
  mainnet: {
    CHAIN: taiko,
  },
};
export const taikoHeklaClient = createPublicClient({
  chain: chain[process.env.REACT_APP_ENV || "testnet"].CHAIN,
  transport: http(),
});
