export const enumMenu = [
  {
    path: "#",
    name: "Mine TAIKO",
    children: [
      {
        path: "/farm",
        name: "Earning",
      },
      {
        path: "/farm",
        name: "Boosters",
      },
      {
        path: "/farm",
        name: "Explorer",
      },
      {
        path: "/farm",
        name: "Referral Program",
      },
    ],
  },
  {
    path: "#",
    name: "Airdrop",
    children: [
      {
        path: "/airdrop",
        name: "Social Quest",
      },
      {
        path: "/airdrop",
        name: "Node Quest",
      },
    ],
  },
  // {
  //   path: "#",
  //   name: "Swap",
  //   disabled: true,
  // },
  {
    path: "#",
    name: "Staking",
    disabled: true,
  },
];

export const Taiko = "TAIKO";
