import React from "react";
//import component
import ProductNode from "./productnode";

const Node = () => {
  return (
    <>
      <section>
        <ProductNode />
      </section>
    </>
  );
};

export default Node;
